import React, { PureComponent } from 'react'
import styles from './style.m.scss'
import baseInputStyles from '../baseInput.scss'

import { FormInput } from '~/components/FormInput'
import { classNames } from '@common/helpers'

export class PhoneInputNew extends PureComponent {
  onInputError = (errMsg) => {
    const { onError } = this.props
    onError && onError(errMsg)
  }

  onInputSuccess = () => {
    const { onSuccess } = this.props
    onSuccess && onSuccess()
  }

  render() {
    const { errorText, className, ...others } = this.props
    const showErrText = !!errorText

    return (
      <div
        className={classNames(
          baseInputStyles.inputWrapper,
          !!showErrText && baseInputStyles.hasError,
          className,
        )}
      >
        <div className={baseInputStyles.inputContent}>
          <div className={`font-roboto-medium ${styles.prefix}`}>
            <img src={require('./images/in.png')} />
            <span>+62</span>
          </div>
          <div className={baseInputStyles.FormInputWrapper}>
            <FormInput
              {...others}
              maxLength={20}
              className={`font-roboto-medium`}
              hasTips={false}
              onError={this.onInputError}
              onSuccess={this.onInputSuccess}
              inputmode="tel"
              type="tel"
              // 补零
              zeroPadding={true}
            />
          </div>
        </div>

        <span className={baseInputStyles.errorText}>{errorText}</span>
      </div>
    )
  }
}
