import { helpers } from '@common/index'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BizTracker from '~/common/tracker/bizTracker'
import Logger from '~/config/logger'
import { Translate } from '~/config/translate'
import { SET_AGREEMENT_SELECTED } from '~/views/login/store/actions'
import styles from './index.scss'
import loggerConfig from '../../logger.config'
import { MouseEvent } from 'react'
import { classNames } from '@/vendor/common @/helpers'
export function AgreementCheckBox(props: {
  className?: string
  isRegister?: boolean
  showPostTokenProtocolFromRegister?: boolean
}) {
  const { className, isRegister, showPostTokenProtocolFromRegister } = props
  const { translate } = Translate.getInstance()

  const { showPostTokenProtocol, agreementSelected } = useSelector((state) => {
    return {
      showPostTokenProtocol: state.getIn([
        'login',
        'homeInfo',
        'showPostTokenProtocol',
      ]),
      agreementSelected: state.getIn(['login', 'agreementSelected']),
    }
  })

  const dispatch = useDispatch()
  const history = useHistory()

  function clickTerm(e: MouseEvent) {
    e.stopPropagation()

    BizTracker.click({ cn: 7, sn: 390001 })

    Logger.getInstance().onPageClick(
      isRegister
        ? loggerConfig.register_click_agreement
        : {
            ...loggerConfig.click_agreement,
            Aku_channelName: 'sms',
          },
    )
    location.href = 'https://www.akulaku.com/artikel/akulakupaylater/' // installment_Agreement
  }

  return (
    <div
      className={classNames(styles.terms, className)}
      onClick={() => {
        BizTracker.click({ cn: 6, sn: 390001 })
        dispatch({
          type: SET_AGREEMENT_SELECTED,
          payload: !agreementSelected,
        })
      }}
    >
      <img
        src={
          agreementSelected
            ? require('./images/checked.png')
            : require('./images/unchecked.png')
        }
        alt=""
      />
      <p>
        <span>
          {isRegister
            ? translate('点击注册代表您已阅读并同意以下条款协议')
            : translate('点击注册代表您已阅读并同意以下')}
        </span>
        <a onClick={clickTerm}>{translate('用户注册')}</a>
        {showPostTokenProtocol || showPostTokenProtocolFromRegister ? (
          <>
            &nbsp;{translate('和')}&nbsp;
            <a
              onClick={(e) => {
                e.stopPropagation()
                location.href = 'https://www.akulaku.com/artikel/tokenakses/'
              }}
            >
              {translate('使用条款')}
            </a>
          </>
        ) : null}
      </p>
    </div>
  )
}
