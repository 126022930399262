import { classNames } from '@common/helpers'
import { Button } from 'antd-mobile'
import { ButtonProps } from 'antd-mobile/es/button'
import { FC } from 'react'
import { Translate } from '~/config/translate'
import { store } from '~/store'
import styles from './index.scss'
import PromotionTag from '~/components/PromotionTag'

type Props = ButtonProps

export const LoginSubmitBtn: FC<Props> = (props) => {
  const { translate } = Translate.getInstance()
  const { children, className, activityInfo, ...rest } = props
  const state = store.getState()

  return (
    <Button
      {...rest}
      type="warning"
      className={classNames(
        `font-roboto-medium`,
        styles.LoginSubmitBtn,
        className,
        { [styles.mt15]: activityInfo?.activityTitle },
      )}
    >
      {children || translate('登录')}
      <PromotionTag>{activityInfo?.activityTitle}</PromotionTag>
    </Button>
  )
}
