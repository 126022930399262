import React from 'react'

import { FormInput } from '~/components/FormInput'
import baseInputStyles from '../baseInput.scss'
import styles from './index.scss'
import { classNames } from '@common/helpers'

export function CodeInputNew(props) {
  const { btnDisable, btnText, errText, hasTips, className } = props
  const showErrText = !hasTips && !!errText

  function onBtnClick() {
    const { btnDisable } = props
    if (btnDisable) {
      return false
    }
    props.onSend()
  }

  return (
    <div
      className={classNames(
        baseInputStyles.inputWrapper,
        styles.CodeInputNew,
        !!showErrText && baseInputStyles.hasError,
        className,
      )}
    >
      <div className={`${baseInputStyles.inputContent} ${styles.inputContent}`}>
        <div
          className={`${baseInputStyles.FormInputWrapper} ${styles.FormInputWrapper}`}
        >
          <div>
            <FormInput
              {...props}
              maxLength={20}
              autocomplete="off"
              hasTips={false}
              className={`font-roboto-medium`}
              inputmode="numeric"
              type={'tel'}
            />
          </div>
          <button
            disabled={btnDisable}
            onClick={onBtnClick}
            className={`font-roboto-medium ${styles.btn}`}
            type="button"
          >
            {btnText}
          </button>
        </div>
      </div>
      <span className={baseInputStyles.errorText}>{errText}</span>
    </div>
  )
}
