import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import BizTracker from '~/common/tracker/bizTracker'

import { globalActionCreator } from '~/common/globalActions'
import { actionCreator } from '../../store'
import { withBasic } from '@common'
import Logger from '~/config/logger'
import loggerConfig from '../../logger.config'

import { FIRST_PAGE_TYPE } from '~/common/macro'

import styles from './style.m.scss'
import { AgreementCheckBox } from '~/views/login/components/AgreementCheckBox'
import { PhoneInputNew } from '~/views/login/components/newInputs/PhoneInputNew'
import { CodeInputNew } from '~/views/login/components/newInputs/CodeInputNew'
import { LoginSubmitBtn } from '~/views/login/components/LoginSubmitBtn'
import { SwitchLoginType } from '~/views/login/components/SwitchLoginType'
import { history } from 'umi'

@withBasic
class SMSLoginForm extends PureComponent {
  constructor() {
    super()
    this.state = {
      phoneNumber: '',
      password: '',
      sent: false,
      countDown: 60,
      isResend: false,
    }
    this.controls = []
    this.saLogger = Logger.getInstance()
  }

  //
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sendSMSCode && !this.props.sendSMSCode) {
      this.props.setSendSMSCode(false)
      this.startCountDown()
    }
  }

  // 切换tab的适合用来同步用户填写的手机号 手机号登录和密码登录
  componentWillUnmount() {
    const { phoneNumber } = this.state
    const { setDefaultPhoneNumber, compType } = this.props
    // 注册页面的这个不需要执行保存
    compType !== FIRST_PAGE_TYPE.REGISTER &&
      phoneNumber &&
      setDefaultPhoneNumber(phoneNumber)
  }

  startCountDown = (cd = 60) => {
    this.setState({ sent: true, isResend: true })
    cd--
    if (cd < 0) {
      this.setState({ sent: false, countDown: 60 })
      return
    }
    setTimeout(() => {
      this.setState({ countDown: cd })
      this.startCountDown(cd)
    }, 1000)
  }

  //保存控件实例到数组中
  register = (instance) => {
    this.controls.push(instance)
  }

  //收集控件value值保存到表单state中
  collect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  beforeSubmit = (filterObj) => {
    const { showGlobalToast } = this.props
    //提交表单前，每一个控件应该通过数据校验
    let result, errMsg
    const includeList = filterObj.includeList

    result = this.controls.every((elem) => {
      if (includeList && !includeList.includes(elem.name)) return true

      if (elem.name === 'password' || elem.name === 'graphicCaptcha')
        return true //不校验密码和图形验证码

      let value =
        elem.state.value === null ? elem.props.defaultValue : elem.state.value
      if (elem.validate(value) === false) {
        return true
      } else {
        errMsg = elem.validate(value)
        showGlobalToast(errMsg)
        return false
      }
    })

    return result
  }

  submitPassword = () => {
    if (this.props.compType !== FIRST_PAGE_TYPE.REGISTER) {
      // 登录的埋点事件
      BizTracker.click({
        cn: 13,
        sn: 390001,
      })
    }
    const {
      SMSLogin,
      setPasswordErrorText,
      setPhoneNumberErrorText,
      defaultPhoneNumber,
      showGlobalToast,
      translate,
      setCodeErrorText,
      agreementSelected,
    } = this.props
    const { captcha, phoneNumber } = this.state

    if (!agreementSelected) {
      showGlobalToast(translate('请勾选条款协议'))
      return
    }

    const result = this.controls.every((elem) => {
      const errMsg = elem.validate(
        elem.state.value === null ? elem.props.defaultValue : elem.state.value,
      )

      if (errMsg === false) {
        return true
      } else {
        if (elem.name === 'password') {
          setPasswordErrorText(errMsg)
        } else if (elem.name === 'captcha') {
          setCodeErrorText(errMsg)
        } else {
          setPhoneNumberErrorText(errMsg)
        }
        return false
      }
    })

    if (result) {
      SMSLogin(
        phoneNumber ? phoneNumber : defaultPhoneNumber,
        captcha,
        this.props.isRegister,
      )
    }
  }

  //如果控件被销毁，则要重置数组
  removeController = (name) => {
    if (!name) return

    this.controls = this.controls.filter((elem) => {
      if (elem.name !== name) return elem
    })
  }

  sendCode = async () => {
    // 点击发送验证码
    BizTracker.click({ cn: 4, sn: 390001 })
    const { sendCodeWithPhoneNumber, defaultPhoneNumber, compType } = this.props
    const { phoneNumber } = this.state

    const result = this.beforeSubmit({ includeList: ['phoneNumber'] })
    const saLogger = Logger.getInstance()
    let loggerInfo =
      compType === FIRST_PAGE_TYPE.REGISTER
        ? loggerConfig.click_register_send
        : { ...loggerConfig.send, Aku_channelName: 'sms' }
    saLogger.onPageClick(
      this.props.isRegister ? loggerConfig.register_sms_click : loggerInfo,
    )

    if (result === true) {
      sendCodeWithPhoneNumber(phoneNumber ? phoneNumber : defaultPhoneNumber)
    }
  }

  render() {
    const {
      translate,
      countryCode,
      setPhoneNumberErrorText,
      codeErrorText,
      phoneNumberErrorText,
      type,
      defaultPhoneNumber,
      setCodeErrorText,
      canModifyPhoneNumber,
      setValidPhoneNumber,
      isRegister,
      showPostTokenProtocolFromRegister,
      activityInfo,
    } = this.props
    const { sent, countDown } = this.state

    return (
      <form key="loginForm" className={styles.formSmsLogin}>
        <PhoneInputNew
          className={styles.mb14}
          name="phoneNumber"
          onChange={(value) => {
            // 验证码手机输入框
            BizTracker.input({
              key: 'sms-login-phoneinput',
              cn: 3,
              ic: value,
              sn: 390001,
            })
            setValidPhoneNumber(value)
          }}
          defaultValue={defaultPhoneNumber}
          placeholder={translate('手机号')}
          onRegister={this.register}
          onControl={this.collect}
          validation={{ type: 'phone' }}
          onSuccess={() => {
            setPhoneNumberErrorText('')
          }}
          onError={(errMsg) => {
            setPhoneNumberErrorText(errMsg)
          }}
          onFocus={() => {
            setPhoneNumberErrorText('')
            this.saLogger.onPageInput(
              isRegister
                ? loggerConfig.register_phone_num
                : {
                    ...loggerConfig.click_phone_num,
                    Aku_channelName: 'sms',
                  },
            )
          }}
          onBlur={(e, value) => {
            this.saLogger.onPageInput({
              ...loggerConfig.input_phone_num,
              Aku_channelName: 'sms',
            })
            setValidPhoneNumber(value)
          }}
          errorText={phoneNumberErrorText}
          countryCode={countryCode}
          required
          platform={type === 'pc' ? 'pc' : 'mobile'}
          hasClearIcon
          disabled={
            canModifyPhoneNumber !== undefined && canModifyPhoneNumber !== null
              ? !canModifyPhoneNumber
              : false
          }
        />

        <CodeInputNew
          name="captcha"
          placeholder={translate('验证码')}
          onRegister={this.register}
          onControl={this.collect}
          validation={{ type: 'code' }}
          onSend={() => this.sendCode()}
          btnText={
            sent
              ? `${countDown}s`
              : this.state.isResend
              ? translate('重新发送')
              : translate('发送')
          }
          btnDisable={sent}
          errText={codeErrorText}
          onFocus={() => {
            setCodeErrorText('')
            this.saLogger.onPageInput(
              isRegister
                ? loggerConfig.register_otp_click
                : {
                    ...loggerConfig.click_password_box,
                    Aku_channelName: 'sms',
                  },
            )
          }}
          onChange={(value) => {
            // 验证码输入框
            BizTracker.input({
              key: 'sms-login-captchainput',
              cn: 5,
              ic: value,
              sn: 390001,
            })
          }}
          onBlur={() => {
            this.saLogger.onPageInput({
              ...loggerConfig.input_password,
              Aku_channelName: 'sms',
            })
          }}
          onSuccess={() => {
            setCodeErrorText('')
            // this.handleCodeInput(true)
          }}
          onError={(errMsg) => {
            setCodeErrorText(errMsg)
            // this.handleCodeInput(false)
          }}
          hasClearIcon
        />

        {isRegister ? (
          <>
            <AgreementCheckBox
              isRegister={true}
              className={styles.mt20}
              showPostTokenProtocolFromRegister={
                showPostTokenProtocolFromRegister
              }
            ></AgreementCheckBox>
            <LoginSubmitBtn
              onClick={this.submitPassword}
              disabled={false /* !codeStatus || !phoneStatus */}
              className={styles.my10}
              activityInfo={activityInfo}
            >
              {translate('注册领取')}
            </LoginSubmitBtn>
            <div
              className={styles.gotoLogin}
              onClick={() => {
                this.saLogger.onPageClick(loggerConfig.register_go_login_click)
                history.push({
                  pathname: '/signin',
                  search: location.search,
                })
              }}
            >
              {translate('已有账号，前往登录')}
            </div>
          </>
        ) : (
          <>
            <SwitchLoginType></SwitchLoginType>

            <LoginSubmitBtn
              onClick={this.submitPassword}
              disabled={false /* !codeStatus || !phoneStatus */}
              className={styles.mb14}
              activityInfo={activityInfo}
            ></LoginSubmitBtn>

            <AgreementCheckBox></AgreementCheckBox>
          </>
        )}
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  codeErrorText: state.getIn(['login', 'codeErrorText']),
  phoneNumberErrorText: state.getIn(['login', 'phoneNumberErrorText']),
  callbackPageUrl: state.getIn(['login', 'callbackPageUrl']),
  defaultPhoneNumber: state.getIn(['login', 'defaultPhoneNumber']),
  curPeriod: state.getIn(['login', 'curPeriod']),
  sendSMSCode: state.getIn(['login', 'sendSMSCode']),
  agreementSelected: state.getIn(['login', 'agreementSelected']),
  canModifyPhoneNumber: state.getIn(['main', 'canModifyPhoneNumber']),
  activityInfo: state.getIn(['login', 'homeInfo', 'activityInfo'])?.toJS(),
})

const mapDispatchToProps = (dispatch) => ({
  setValidPhoneNumber(validPhoneNumber) {
    dispatch(actionCreator.goSetCurValidPhoneNumber(validPhoneNumber))
  },
  sendCodeWithPhoneNumber(phoneNumber) {
    return dispatch(actionCreator.goSendCodeWithPhoneNumber({ phoneNumber }))
  },
  setPasswordErrorText(text) {
    dispatch(actionCreator.goSetPasswordErrorText(text))
  },
  setPhoneNumberErrorText(text) {
    dispatch(actionCreator.goSetPhoneNumberErrorText(text))
  },
  setCodeErrorText(text) {
    dispatch(actionCreator.goSetCodeErrorText(text))
  },
  SMSLogin(phoneNumber, captcha, isRegister) {
    dispatch(actionCreator.goSMSLogin(phoneNumber, captcha, isRegister))
  },
  showGlobalToast(errMsg) {
    dispatch(globalActionCreator.toggleToast(true, errMsg))
  },
  setDefaultPhoneNumber(phoneNumber) {
    dispatch(actionCreator.goSetDefaultPhoneNumber(phoneNumber))
  },
  setSendSMSCode(sendSMSCode) {
    dispatch(actionCreator.setSendSMSCode(sendSMSCode))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SMSLoginForm)
