import { classNames } from '@common/helpers'
import { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FIRST_PAGE_LOGIN_TYPE } from '~/common/macro'
import BizTracker from '~/common/tracker/bizTracker'
import { Translate } from '~/config/translate'
import { actionCreator } from '~/views/login/store'
import styles from './index.scss'

export const SwitchLoginType: FC<{ className?: string }> = (props) => {
  const { className } = props
  const dispatch = useDispatch()
  const { translate } = Translate.getInstance()
  const loginType = useSelector((state) => state.getIn(['login', 'loginType']))

  const currentIsSms = loginType === FIRST_PAGE_LOGIN_TYPE.SMS

  const onSwitch = () => {
    BizTracker.click({ cn: currentIsSms ? 2 : 8, sn: 390001 })
    dispatch(
      actionCreator.goSetLoginType(
        currentIsSms
          ? FIRST_PAGE_LOGIN_TYPE.ACCOUNT
          : FIRST_PAGE_LOGIN_TYPE.SMS,
      ),
    )
  }

  return (
    <div
      className={classNames(
        className,
        styles.SwitchLoginType,
        `font-roboto-medium`,
      )}
    >
      <span onClick={onSwitch}>
        {currentIsSms ? translate('密码登录') : translate('短信登录')}
      </span>

      {props.children}
    </div>
  )
}
